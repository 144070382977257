
























































import { defineComponent } from '@vue/composition-api';
import store from '@/store';
import Input from '@/components/form/input.vue';
import Login from '@/partials/Login.vue';
import useContext, { IUseContext } from '@/composition/context';

export default defineComponent({
  components: { Input, Login },
  setup() {
    const { translations, relatedEvents } = useContext() as unknown as IUseContext;
    return { translations, relatedEvents };
  },
  created() {
    store.commit('setContextEvent', {});
  },
});
